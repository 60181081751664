<template>
  <div class="aipic">
    <Header class="app-title" callBack @click="goBack">
      <template>ChatGPT帮你创建虚拟伴侣</template>
      <template v-slot:right v-if="this.$route.query.platform"
        ><van-icon name="share-o" class="fenxiang" @click="shareLink"
      /></template>
    </Header>
    <div class="container">
      <div v-if="picStatus">
        <div class="title-1">
          <img src="../../assets/images/aipic/title-1.png" />
        </div>
        <div class="title-text">关键字设置</div>
        <div class="text-div">
          <textarea
            class="aipic-textarea"
            @input="textareaChange"
            v-model="prompt"
            rows="10"
            placeholder="多个关键字以逗号拼接（例：温柔，漂亮，可爱，善良，长发，美女）"
          ></textarea>
        </div>
        <div class="button-blue">
          <van-button block v-if="buttonStatus" class="btn-none"
            >开始生成</van-button
          >
          <van-button block v-else type="primary" class="blue" @click="goAiPic"
            >开始生成</van-button
          >
        </div>
      </div>
      <div v-else>
        <div class="title-2">
          <img src="../../assets/images/aipic/title-2.png" />
        </div>
        <div class="aipic-img-div">
          <img :src="aipicUrl" class="aipic-img" />
          <div class="aipic-img-name">{{ prompt }}</div>
        </div>
        <div class="aipic-button">
          <van-button block type="primary" class="blue" @click="showSave()"
            >铸造为专属藏品</van-button
          >
        </div>
      </div>
      <van-overlay :show="showCenter" @click="showCenter = false">
        <div class="show-center-class" @click.stop>
          <div class="aipic-title">藏品名称</div>
          <div class="aipic-name">
            <input v-model="aipicName" placeholder="输入藏品名称（10字以内）" />
          </div>
          <div class="aipic-save">
            <van-button block type="primary" class="blue" @click="savePic()"
              >铸造为专属藏品(需支付5.2元)</van-button
            >
          </div>
        </div>
      </van-overlay>
      <div
        class="show-center-class"
        v-show="saveSuccess"
        @click="saveSuccess = false"
      >
        <div class="aipic-title">提示</div>
        <div class="aipic-content">藏品生成成功</div>
        <div class="aipic-save-btn">
          <van-button block type="primary" class="blue">确认</van-button>
        </div>
      </div>
      <div
        class="show-center-class"
        v-show="saveFail"
        @click="saveFail = false"
      >
        <div class="aipic-title">提示</div>
        <div class="aipic-content">{{ tip }}</div>
        <div class="aipic-save-btn">
          <van-button block type="primary" class="blue">确定</van-button>
        </div>
      </div>
      <van-overlay :show="showBox">
        <div class="wrapper">
          <div class="block">
            <div>请在APP内完成</div>
            <div class="over_box">
              <div class="over_none" @click="showBox = false">取消</div>
              <div class="over_en" @click="openApp">去下载app</div>
            </div>
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import { addBridgeForAndroidWebView } from 'sdbridge-android';
import Axios from 'axios';

export default {
  name: 'aipic',
  data() {
    return {
      aipicUrl: '',
      picStatus: true,
      buttonStatus: true,
      prompt: '',
      showCenter: false,
      aipicName: '',
      saveSuccess: false,
      saveFail: false,
      tip: '',
      token: '',
      isAndroid:
        navigator.userAgent.indexOf('Android') > -1 ||
        navigator.userAgent.indexOf('Adr') > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      showBox: false,
    };
  },
  methods: {
    goBack() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        } else {
          this.bridge.callHandler(
            'jumptoapp',
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            },
          );
        }
      } else {
        this.$router.back();
      }
    },
    async goAiPic() {
      //判断是否登陆
      let isLogin = this.isLogin();
      if (!isLogin) {
        return;
      }
      //查询是否拥有次数
      let num = await this.getIsView();
      if (!num) {
        //看视频广告
        this.lookVideo();
        //判断次数
        let num_two = await this.getIsView();
        if (!num_two) {
          return false;
        }
      }

      //请求生成图片
      let prompt = this.$data.prompt;
      if (prompt.length > 0) {
        let loading = this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        });
        try {
          let response = await Axios.post('/ai/getImage', { prompt: prompt });
          loading.close();
          if (response.status == 200) {
            if (typeof response.data.url == 'string') {
              this.$data.picStatus = false;
              this.$data.aipicUrl = response.data.url;
            } else {
              this.$toast('关键字识别失败，换个关键字再试试吧！');
            }
          } else {
            this.$toast('关键字识别失败，换个关键字再试试吧！');
          }
        } catch (error) {
          this.$toast('关键字识别失败，换个关键字再试试吧！');
        }
      } else {
        this.$toast('请输入内容');
      }
    },
    async showSave() {
      this.$data.showCenter = true;
    },
    async savePic() {
      let aipicName = this.$data.aipicName;
      let aipicUrl = this.$data.aipicUrl;
      let token = this.$data.token;
      if (aipicName.length > 0) {
        this.$data.showCenter = false;
        let response = await this.$api.userCreatedGood({
          name: aipicName,
          pic: aipicUrl,
          token: token,
        });
        this.$data.saveSuccess = false;
        if (response.code == 0) {
          this.$data.saveSuccess = true;
        } else {
          this.$data.saveFail = true;
          this.tip = response.msg;
        }
      } else {
        this.$toast('请输入内容');
      }
    },
    textareaChange() {
      let prompt = this.$data.prompt;
      if (prompt.length > 0) {
        this.$data.buttonStatus = false;
      } else {
        this.$data.buttonStatus = true;
      }
    },
    // goLook() {
    //   let url = '/user';
    //   if (this.$route.query.platform) {
    //     this.$router.push(
    //       `${url}?token=${this.$route.query.token}&platform=${this.$route.query.platform}&from=h5`,
    //     );
    //   } else {
    //     this.$router.push(`${url}`);
    //   }
    // },
    shareLink() {
      if (this.$route.query.platform) {
        const host = process.env.VUE_APP_HOST;
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(
            JSON.stringify({
              key: 13,
              logo: 'https://jueyitest.oss-cn-beijing.aliyuncs.com/uploads/images/goods/2023021416273906189.jpg',
              title: '来华夏数艺在线生成你心目中的TA',
              subTitle: '首个基于ChatGPT技术铸造的NFT数字藏品',
              link: `${host}/#/aipic`,
            }),
          );
        } else {
          this.bridge.callHandler(
            'jumptoapp',
            {
              key: 13,
              logo: 'https://jueyitest.oss-cn-beijing.aliyuncs.com/uploads/images/goods/2023021416273906189.jpg',
              title: '来华夏数艺在线生成你心目中的TA',
              subTitle: '首个基于ChatGPT技术铸造的NFT数字藏品',
              link: `${host}/#/aipic`,
            },
            function (response) {
              console.log(JSON.stringify(response));
            },
          );
        }
      }
    },
    lookVideo() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: 12 }));
        } else {
          this.bridge.callHandler(
            'jumptoapp',
            { key: 12 },
            function (response) {
              console.log(JSON.stringify(response));
            },
          );
        }
      } else {
        this.showBox = true;
      }
    },
    isLogin() {
      //判断是否登陆
      let token = this.token;
      if (!token) {
        if (this.$route.query.platform) {
          if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            control.jumptoapp(JSON.stringify({ key: -1 }));
          } else if (this.isiOS) {
            this.bridge.callHandler(
              'jumptoapp',
              { key: -1 },
              function (response) {
                console.log(JSON.stringify(response));
              },
            );
          }
        } else {
          this.$router.push('/login');
          return false;
        }
      } else {
        return true;
      }
    },
    async getIsView() {
      //查询是否拥有次数
      let token = this.token;
      try {
        let resNum = await this.$api.aiPictureIsView({
          token: token,
        });
        if (resNum.code != 0) {
          this.$toast(resNum.msg);
          return false;
        } else {
          if (resNum.state == 1) {
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        this.$toast('请求失败');
        return false;
      }
    },
    openApp() {
      window.open('https://v.hxnft.shop/appDownload/index.html');
      this.showBox = false;
    },
  },
  mounted() {
    // this.token = localStorage.getItem('token');
    this.token = this.$route.query.token;

    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log('TypeScript正在尝试挂载');
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          'window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!',
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log('window.WebViewJavascriptBridge 挂载成功！！');
    }
    this.bridge.registerHandler('AdVoideoDone', () => {
      this.goAiPic();
    });
    const that = this;
    window.AdVoideoDone = () => {
      that.goAiPic();
    };
  },
};
</script>
<style lang="less" scoped>
.text-center {
  text-align: center;
}
.container {
  left: 0;
  right: 0;
  bottom: 0;
  top: 50px;
  overflow: auto;
  position: fixed;
  background: url('~@/assets/images/aipic/background.jpg') no-repeat;
  background-size: 100% 100%;
  font-size: 13px;
  padding: 0 17px;
}
.title-text {
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
  margin-top: 20px;
}
.blue {
  color: #fff;
  background-color: #ff65af;
  border: 0.02667rem solid #ff65af;
  border-radius: 5px;
}
.text-div {
  border: 0.5px solid #ffffff !important;
  background-color: rgba(142, 158, 255, 0.65);
  border: 0;
  margin-top: 14px;
  border-radius: 5px;
  width: 100%;
  color: #fff;
}
.aipic-textarea {
  background-color: rgba(142, 158, 255, 0);
  width: 92%;
  margin: 4%;
  border: 0;
  color: #fff;
}
.aipic-textarea::placeholder {
  color: #fff;
}
.button-blue {
  margin-top: 42px;
}
.aipic-img {
  width: 100%;
}
.aipic-success {
  text-align: center;
  height: 16px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #089c4a;
  line-height: 20px;
  margin: 24px;
}
.aipic-button {
  margin-top: 58px;
}
.show-center-class {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  height: 210px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
}
.aipic-title {
  margin: 25px 0;
  height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 31px;
}
.aipic-name {
  width: 100%;
  height: 44px;
  background: #f8f8f8;
  margin: 25px 0;
  input {
    width: 100%;
    height: 44px;
    border: 0;
    background: #f8f8f8;
    text-align: center;
  }
}
.aipic-save {
  button {
    width: 80%;
    margin: 0 auto;
  }
}
.aipic-content {
  height: 15px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 50px;
}
.aipic-save-btn {
  margin-top: 50px;
  button {
    width: 50%;
    margin: 0 auto;
  }
}
.app-title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #000000;
}
.title-1 > img,
.title-2 > img {
  margin-top: 27px;
  height: 27px;
}
.btn-none {
  color: #999999;
  border-radius: 5px;
}
.fenxiang {
  font-size: 22px;
}
.block {
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  padding-top: 30px;
  width: 300px;
  margin: 0 auto;
  margin-top: 60%;
  background-color: #fff;
}
.over_box {
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;
}

.over_none {
  color: #0754d3;
  width: 74px;
  text-align: center;
  line-height: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px #cecece solid;
}

.over_en {
  color: #ffffff;
  width: 74px;
  text-align: center;
  background-color: #0754d3;
  line-height: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px #0754d3 solid;
}
.aipic-img-div {
  margin-top: 27px;
  width: 100%;
  background: #fff;
  padding: 10px 8px;
  box-sizing: border-box;
}
.aipic-img-name {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
  margin: 11px 0 9px 0;
}
</style>
